var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name-input","invalid-feedback":"Name is required"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.submitted && _vm.v$.name.$error,
        },attrs:{"id":"name-input","state":_vm.validateState('name'),"placeholder":"enter a name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label":"Code","label-for":"code-input","invalid-feedback":"Code is required"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.submitted && _vm.v$.code.$error,
        },attrs:{"id":"code-input","state":_vm.validateState('code'),"placeholder":"enter a code. example INR or USD","formatter":_vm.formatter},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),(_vm.submitted && _vm.v$.code.maxLength)?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" maximum 4 character is allowed ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Symbol","label-for":"symbol-input","invalid-feedback":"Symbol is required"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.submitted && _vm.v$.symbol.$error,
        },attrs:{"id":"symbol-input","state":_vm.validateState('symbol'),"placeholder":"enter a symbol"},model:{value:(_vm.symbol),callback:function ($$v) {_vm.symbol=$$v},expression:"symbol"}}),(_vm.submitted && _vm.v$.symbol.maxLength)?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" maximum 1 character is allowed ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Status","label-for":"status-input","invalid-feedback":"Status is required"}},[_c('b-form-checkbox',{class:{
          'is-invalid': _vm.submitted && _vm.v$.status.$error,
        },attrs:{"name":"check-status","state":_vm.validateState('status'),"switch":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.status ? "Active" : "Inactive")+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }